import { graphql } from 'gatsby';
import React, { useContext } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import { Form, TrustedBrandIcons } from '../components/PageComps/CommonComps';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import FAQs from '../components/PageComps/FAQsPageComps/FAQs';
import { DataContext } from '../providers/DataProvider';
import { getFormData } from '../utils/utils';

const FaqsPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const { sanityFaqsPage } = data;
  const { pageHead } = sanityFaqsPage;
  const { title, subTitle } = pageHead;
  const formData = getFormData({ data });
  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="0% 50%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={undefined}
            titlePaddingBottom={30}
            largeSubTitleSize
          />
        }
      />

      <FAQs data={sanityFaqsPage.faqs} />

      <Form data={formData} />

      <Separator height={100} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default FaqsPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityFaqsPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityFaqsPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      faqs {
        title
        text
        category
      }
    }
    sanityHomePage(language: { eq: $language }) {
      pageHead {
        contactForm {
          title
          subTitle
          demoFormFields {
            key
            label
            required
            multiline
          }
          buttonName
        }
        referralOptions {
          referralOptionsList {
            optionName
            showOption
          }
        }
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
  }
`;
